@import './_functions.scss';

@mixin header22($important: false) {
  font-size: 22px is-important($important);
  line-height: 30px is-important($important);
  font-weight: 900 is-important($important);
};
@mixin header20($important: false) {
  font-size: 20px is-important($important);
  line-height: 28px is-important($important);
  font-weight: 700 is-important($important);
};
@mixin header18($important: false) {
  font-size: 18px is-important($important);
  line-height: 26px is-important($important);
  font-weight: 700 is-important($important);
};
@mixin header16($important: false) {
  font-size: 16px is-important($important);
  line-height: 24px is-important($important);
  font-weight: 700 is-important($important);
};
@mixin header14($important: false) {
  font-size: 14px is-important($important);
  line-height: 22px is-important($important);
  font-weight: 700 is-important($important);
};
@mixin body18($important: false) {
  font-size: 18px is-important($important);
  line-height: 26px is-important($important);
  font-weight: 400 is-important($important);
};
@mixin body16($important: false) {
  font-size: 16px is-important($important);
  line-height: 24px is-important($important);
  font-weight: 400 is-important($important);
};
@mixin body14($important: false) {
  font-size: 14px is-important($important);
  line-height: 22px is-important($important);
  font-weight: 400 is-important($important);
};
@mixin body12($important: false) {
  font-size: 12px is-important($important);
  line-height: 20px is-important($important);
  font-weight: 400 is-important($important);
};

@use '../variables/colors' as c;
@use '../background' as background;
@use '../font-colors' as color;
@use '../text' as text;
@import '~antd/dist/antd.compact.css';

.ant-menu {
    @include text.body16;
    @include color.white;
}
.ant-menu-item, .ant-menu-submenu-title {
    @include text.body16;
    @include color.white;
    a.ant-typography, .ant-typography a {
        @include text.header16;
        @include color.white;
    }
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
    @include background.subBlue1;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    @include background.subBlue1;

    a.ant-typography, .ant-typography a {
        @include text.header16;
        @include color.white;
    }
}
.ant-menu.ant-menu-sub {
    @include background.subBlue1;

    .ant-menu-item {
        &.ant-menu-item-selected {
            @include background.subBlue1;
        }
        a.ant-typography, .ant-typography a {
            @include text.body14;
            @include color.white;
        }
    }
}
.ant-menu-submenu > .ant-menu {
    @include background.primaryBlue;
    border-radius: 2px;
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
    height: 40px;
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 40px;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 40px;
}

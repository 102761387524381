@use 'variables/colors' as c;
@import './_functions.scss';

@mixin primaryBlue ($important: false) {
  background-color: c.$primaryBlue is-important($important);
}
@mixin subBlue1 ($important: false) {
  background-color: c.$subBlue1 is-important($important);
}
@mixin subBlue2 ($important: false) {
  background-color: c.$subBlue2 is-important($important);
}
@mixin subBlue3 ($important: false) {
  background-color: c.$subBlue3 is-important($important);
}
@mixin subBlue4 ($important: false) {
  background-color: c.$subBlue4 is-important($important);
}
@mixin green ($important: false) {
  background-color: c.$green is-important($important);
}
@mixin red ($important: false) {
  background-color: c.$red is-important($important);
}
@mixin yellow ($important: false) {
  background-color: c.$yellow is-important($important);
}
@mixin white ($important: false) {
  background-color: c.$white is-important($important);
}
@mixin gray2 ($important: false) {
  background-color: c.$gray2 is-important($important);
}
@mixin gray3 ($important: false) {
  background-color: c.$gray3 is-important($important);
}
@mixin gray4 ($important: false) {
  background-color: c.$gray4 is-important($important);
}
@mixin gray5 ($important: false) {
  background-color: c.$gray5 is-important($important);
}
@mixin gray6 ($important: false) {
  background-color: c.$gray6 is-important($important);
}
@mixin gray7 ($important: false) {
  background-color: c.$gray7 is-important($important);
}
@mixin gray8 ($important: false) {
  background-color: c.$gray8 is-important($important);
}
@mixin gray9 ($important: false) {
  background-color: c.$gray9 is-important($important);
}
@mixin gray10 ($important: false) {
  background-color: c.$gray10 is-important($important);
}
